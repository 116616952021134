import React from "react";
import Work from "./Work";

const Experience = (props) => {
  const jobs = props.data.work.map((job) => (
    <Work key={job.id} workInfo={job} />
  ));
  return (
    <div id="experience">
      <div className="experience-intro">
        <h2>Experience</h2>
      </div>

      <div className="job-list">{jobs}</div>
    </div>
  );
};

export default Experience;

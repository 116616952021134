import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";
import Project from "./Project";

const Projects = (props) => {
  const projectData = props.data.projects;

  const uniqueTech = [
    ...new Set(
      projectData
        .map((project) => {
          return project.techStack;
        })
        .flat()
    ),
  ];

  const options = [];

  uniqueTech.forEach((tech) => {
    options.push({
      key: tech,
      text: tech,
      value: tech,
      image: {
        avatar: true,
        src: "./images/icons/" + tech + ".svg",
        className: "techIcon",
      },
    });
  });

  let [selectedTech, setSelectedTech] = useState("");

  const allProjects = projectData.map((project) => (
    <Project key={project.id} projectInfo={project} />
  ));

  let filteredProjects = projectData
    .filter((project) => project.techStack.includes(selectedTech))
    .map((filteredProject) => (
      <Project key={filteredProject.id} projectInfo={filteredProject} />
    ));

  const handleChange = (event) => {
    if (event.target.closest(".item")) {
      setSelectedTech(event.target.closest(".item").children[1].innerHTML);
    }
    if (event.target.className.includes("dropdown icon")) {
      setSelectedTech("");
    }
  };

  const renderedProjects = selectedTech === "" ? allProjects : filteredProjects;

  return (
    <div id="projects">
      <div className="project-intro">
        <h2>Projects</h2>
      </div>
      <div className="project-filter ">
        <span className="project-filter-text">Show me projects built with</span>
        <span>
          <Dropdown
            placeholder="..."
            selection
            clearable
            options={options}
            onChange={handleChange}
          />
        </span>
      </div>
      <div className="projects-container">{renderedProjects}</div>
    </div>
  );
};

export default Projects;

import React from "react";
import data from "../data";
import Intro from "./Intro";
import AboutMe from "./AboutMe";
import Experience from "./Experience";
import Projects from "./Projects";
import NaviBar from "./NaviBar";
import "./styles/style.css";
import GetInTouch from "./GetInTouch";
import Outro from "./Outro";

const App = () => {
  return (
    <div>
      <div className="container">
        <NaviBar data={data} />
        <Intro data={data} />
        <AboutMe data={data} />
        <Experience data={data} />
        <Projects data={data} />
        <GetInTouch />
        <Outro />
        <NaviBar footer={true} />
        <div className="eof">
          <div className="color-box red"></div>
          <div className="color-box orange"></div>
          <div className="color-box blue"></div>
          <div className="color-box grey"></div>
        </div>
      </div>
    </div>
  );
};

export default App;

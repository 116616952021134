import React from "react";
import "./styles/Outro.css";

const Outro = () => {
  return (
    <div className="outro">
      <p className="location">London, UK</p>
      <p className="email">ceciliamarcellesi@gmail.com</p>
      <p className="phone">07435017412</p>
      <div className="social-links">
        <a href="http://github.com/ceci-ma">
          <i className="fab fa-github"></i>
        </a>
        <a href="https://www.linkedin.com/in/ceciliamarcellesi">
          <i className="fab fa-linkedin"></i>
        </a>
      </div>
    </div>
  );
};

export default Outro;

import React from "react";
import "./styles/AboutMe.css";

const AboutMe = (props) => {
  return (
    <div className="box">
      <h2>About Me</h2>
      <p>{props.data.introShort}</p>
    </div>
  );
};

export default AboutMe;

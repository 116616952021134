const data = {
  name: "Cecilia Marcellesi",
  role: "Junior Software Engineer",
  photo: "./images/ceci_photo.png",
  introShort:
    "I'm a Junior Software Engineer, with 4 years experience working in eCommerce in London. ",
  linkedinURL: "https://www.linkedin.com/in/ceciliamarcellesi/",
  githubURL: "https://github.com/ceci-ma",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/ceciliamarcellesi",
      className: "fa fa-linkedin",
    },
    {
      name: "github",
      url: "http://github.com/ceci-ma",
      className: "fa fa-github",
    },
  ],

  work: [
    {
      id: 0,
      companyName: "LoyaltyLion",
      role: "Junior Software Engineer",
      joiningDate: "March 2020",
      leavingDate: "May 2020",
      description: [
        "Work on both backend (e.g. Rails, TypeScript, Node.js) and frontend code (e.g. React, Redux, JavaScript)",
        "Work closely with product managers and designers to deliver features end-to-end",
      ],
      style: "blue center",

      logo:
        "https://apps.shopifycdn.com/listing_images/98fe3014a0ddc555aa03aef1fc14d5b2/icon/4bda1ca611891b61da246ed8466891b7.png",
    },
    {
      id: 1,
      companyName: "Le Wagon",
      role: "Teaching Assistant",
      joiningDate: "January 2020",
      leavingDate: "Present",
      description: [
        "Explaining core concepts, helping students to solve challenging problems and ensuring students adopt best practices early on.",
      ],
      style: "purple left",
      logo:
        "https://dwj199mwkel52.cloudfront.net/assets/lewagon-logo-square-fe76916e1b923ade71e253ae6dc031d936e5e8eebac4e26b0fbac650ea6ee360.png",
    },
    {
      id: 2,
      companyName: "LoyaltyLion",
      role: "Senior Customer Success Manager",
      joiningDate: "October 2017",
      leavingDate: "June 2019",
      description: [
        "Managed a team of customer success specialists and implemented training for team ",
        "Designed enterprise client onboarding processes",
        "Exceeded annual goals with a client retention rate above 97% for over 3000 accounts",
      ],
      style: "orange right",

      logo:
        "https://apps.shopifycdn.com/listing_images/98fe3014a0ddc555aa03aef1fc14d5b2/icon/4bda1ca611891b61da246ed8466891b7.png",
    },
    {
      id: 3,
      companyName: "Euromonitor",
      role: "Senior Account Manager",
      joiningDate: "May 2015",
      leavingDate: "October 2017",
      description: [
        "Worked with academic institutions across Europe to provide research solutions tailored to their data needs and strategic priorities.",
      ],
      logo:
        "https://blog.euromonitor.com/wp-content/uploads/2019/07/eVerSlicesRgbK-300x300.png",
      style: "blue center",
    },
  ],
  projects: [
    {
      id: 0,
      name: "Halfway",
      description:
        "A progressive web app that provides recommendations for venues at the halfway point between users' locations.",
      image: "./images/halfway.png",
      techStack: [
        "ruby",
        "heroku",
        "javascript",
        "rails",
        "sass",
        "postgresql",
      ],
      style: "blue",
      github: "https://github.com/ceci-ma/halfway",
    },
    {
      id: 1,
      name: "Cribnb",
      description: "An Airbnb like site, built in 5 days by a team of 4",
      image: "./images/cribnb.png",
      techStack: ["ruby", "rails", "javascript", "heroku"],
      imageClass: "large",
      style: "purple",
      github: "https://github.com/ceci-ma/cribnb",
    },

    {
      id: 2,
      name: "Forkify",
      description: "Search recipes and add them to your shopping list.",
      image: "./images/forkify.png",
      techStack: ["javascript"],
      style: "orange",
      imageClass: "medium",

      github: "https://github.com/ceci-ma/forkify",
    },

    {
      id: 3,
      name: "Catch of The Day",
      description:
        "A single page restaurant menu management app. Deployed on Netlify",
      image: "./images/catch-of-the-day.png",
      techStack: ["react", "javascript"],
      style: "blue",
      imageClass: "medium",
      github: "https://github.com/ceci-ma/catch-of-the-day",
    },
  ],
  testimonials: [
    {
      name: "Patrick Molgaard",
      description:
        "I'd jump at the chance to work with Cecilia again. On top of the professional recommendations she's received already (which I wholly agree with), she brings two particular qualities to the table as an engineer: First, she has an attitude of mastering the fundamentals -- exposed to new tools or technologies, she consistently puts in the focus and the effort to truly understand how they work. Second, her background in Customer Success gives her a developer super-power - the level of user empathy that can only be learned through extensive experience talking to users and experiencing a product from their perspective. She demonstrated this rapidly, contributing to discussion on product requirements and suggesting creative solutions which straddle the engineering/product boundary.",
      title: "Development Team Lead at LoyaltyLion",
    },
    {
      description:
        "Cecilia joined LoyaltyLion when we had under 1,000 customers and she helped scale the team so we could offer high-quality support to 4,000+. She is extremely hardworking, passionate and a true team player. She is better suited to fast-paced companies than most because she is both naturally inquisitive, comfortable with ambiguity and responds quickly to change. I would hire her again in a heartbeat.",
      name: "Charlie Casey",
      title: "LoyaltyLion CEO",
    },
  ],
};

export default data;

import React from "react";
import "./styles/TechStack.css";

const TechStack = (props) => {
  const techName = props.tech;
  return (
    <div className={"tech-container " + techName}>
      <div className="tech-icon ">
        <i className={"devicon-" + techName + "-plain colored"}></i>
      </div>
      <span>{techName}</span>
    </div>
  );
};

export default TechStack;

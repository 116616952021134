import React from "react";
import "./styles/Work.css";

const Work = (props) => {
  const {
    companyName,
    description,
    joiningDate,
    leavingDate,
    role,
    logo,
    style,
  } = props.workInfo;

  const roleDetail = description.map((detail, index) => {
    return <li key={index}>{detail}</li>;
  });

  return (
    <div className={"experience-container  d-flex align-items-center " + style}>
      <div className="d-flex experience-content">
        <div className="logo align-self-start">
          <img src={logo} alt="" />
        </div>
        <div className="role-info">
          <div className="role-title">
            <h3>
              {role} <span>@ {companyName}</span>
            </h3>
          </div>
          <div className="role-dates">
            <p>
              {joiningDate} - {leavingDate}
            </p>
          </div>
          <div className="role-description d-flex flex-column">
            <ul>{roleDetail}</ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;

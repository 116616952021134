import React from "react";
import "./styles/GetInTouch.css";

const GetInTouch = () => {
  return (
    <div className="getintouch box pink-orange">
      <div className="content">
        <h2>Get in touch</h2>
        <p>Let's work together</p>
      </div>
      <a
        className="btn btn-flat contact"
        href="mailto:ceciliamarcellesi@gmail.com"
      >
        LET'S CHAT
      </a>
    </div>
  );
};

export default GetInTouch;

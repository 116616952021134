import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import "./styles/NavBar.css";

const NaviBar = ({ footer }) => {
  return (
    <div className={"navbar-container " + (footer ? "footer" : "")}>
      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="nav-content">
          <Nav className="mr-auto ">
            <Nav.Link className="nav-link" href="#intro">
              ABOUT ME
            </Nav.Link>
            <Nav.Link className="nav-link" href="#experience">
              EXPERIENCE
            </Nav.Link>
            <Nav.Link className="nav-link" href="#projects">
              PROJECTS
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NaviBar;

import React from "react";

import "./styles/Project.css";
import TechStack from "./TechStack";
import PhotoModal from "./PhotoModal";

const Project = (props) => {
  const {
    id,
    name,
    description,
    image,
    techStack,
    imageClass,
    style,
    github,
  } = props.projectInfo;

  const tech = techStack.map((lang) => {
    return <TechStack key={lang} tech={lang} />;
  });

  if (id % 2 === 0) {
    return (
      <div className={"project-container " + style}>
        <div className="photos ">
          <img className={imageClass} src={image} alt="" />
        </div>
        <div>
          <div className="project-content ">
            <div className="tech-stack">{tech}</div>
            <h3>
              {name}

              <a href={github} target="_blank" rel="noopener noreferrer">
                <i className="devicon-github-plain"></i>
              </a>
            </h3>
            <p>{description}</p>
          </div>
        </div>
        <div className="photo-button">
          <PhotoModal source={image} name={name} />
        </div>
      </div>
    );
  } else {
    return (
      <div className={"project-container flipped " + style}>
        <div className="project-content">
          <div className="tech-stack">{tech}</div>
          <h3>
            {name}
            <a href={github} target="_blank" rel="noopener noreferrer">
              <i className="devicon-github-plain"></i>
            </a>
          </h3>

          <p>{description}</p>
        </div>
        <div className="photos ">
          <img className={imageClass} src={image} alt="" />
        </div>
        <div className="photo-button">
          <PhotoModal source={image} name={name} />
        </div>
      </div>
    );
  }
};

export default Project;

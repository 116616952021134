import React from "react";
import "./styles/Intro.css";

const Intro = (props) => {
  const { name, role, photo } = props.data;
  return (
    <div id="intro" className="intro-me grid">
      <div className="six wide column intro-content">
        <h1>{name}</h1>
        <p className="role">{role.toUpperCase()}</p>
        <a className="btn btn-flat" href="mailto:ceciliamarcellesi@gmail.com">
          LET'S CHAT
        </a>
      </div>
      <div className="me ten wide column">
        <img src={photo} alt="" />
      </div>
    </div>
  );
};

export default Intro;

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const PhotoModal = ({ source, image, name }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="photo-modal">
      <Button className="btn-flat" variant="" onClick={handleShow}>
        view project photos
      </Button>

      <Modal show={show} onHide={handleClose} animation={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {<img src={source} alt="" className="project-photo" />}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PhotoModal;
